var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "resizable-text-container",
      class: { scrollbar: _vm.scrollbar }
    },
    [
      _c("h1", {
        ref: "text",
        staticClass: "resizable-text",
        domProps: { innerHTML: _vm._s(_vm.localText) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }