<template>
  <div
    ref="container"
    class="resizable-text-container"
    :class="{ scrollbar: scrollbar }"
  >
    <h1 class="resizable-text" ref="text" v-html="localText"></h1>
  </div>
</template>

<script>
import { TweenMax } from "gsap/TweenMax"
export default {
  name: "ResponsiveText",
  data() {
    return {
      fontSize: 25,
      minFontSize: 16,
      maxFontSize: 30,
      maxPregameFontSize: 27,
      scrollbar: false,
      localText: ""
    }
  },
  props: {
    message: String,
    // onmount animation on and off
    transition: {
      type: Boolean,
      default: true
    },
    // onmount animation delay
    delay: {
      type: Number,
      default: 0.8
    },
    // false makes it resize just once
    // on onmount event
    update: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onTick() {
      const {
        $refs: { text, container },
        minFontSize,
        maxFontSize,
        num
      } = this
      if (text && container) {
        let i = 0
        // num is a diff between min and max e.g. how many possible iterations
        // we need
        for (i = 0; i < num; i++) {
          if (
            text.scrollHeight <= container.clientHeight &&
            this.fontSize < maxFontSize
          ) {
            this.fontSize = this.fontSize + 1
            text.style.fontSize = this.fontSize + "px"
          } else {
            // if it's a match, stop iteration
            break
          }
        }
        // always try to do a step back
        for (i = 0; i < num; i++) {
          if (
            text.scrollHeight >= container.clientHeight &&
            this.fontSize > minFontSize
          ) {
            this.fontSize = this.fontSize - 1
            text.style.fontSize = this.fontSize + "px"
          } else {
            if (text.scrollHeight > container.clientHeight) {
              this.scrollbar = true
            } else {
              this.scrollbar = false
            }
            break
          }
        }
      }
    }
  },
  computed: {
    num() {
      return this.maxFontSize - this.minFontSize
    },
    isPreGame() {
      return this.$route.name === "pregame"
    }
  },
  watch: {
    message(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        if (this.transition) {
          try {
            TweenMax.killTweensOf(this.$refs.text)
            TweenMax.to(this.$refs.text, 0.5, {
              opacity: 0,
              onComplete: () => {
                this.localText = newValue
                TweenMax.to(this.$refs.text, 0.5, { opacity: 1, delay: 0.25 })
              }
            })
          } catch (e) {
            console.warn(e.message)
            this.localText = newValue
          }
        } else {
          this.localText = newValue
        }
      }
    }
  },
  created() {
    if (this.update) {
      TweenMax.ticker.addEventListener("tick", this.onTick)
    }
    if (this.isPreGame) this.maxFontSize = this.maxPregameFontSize
  },
  mounted() {
    this.localText = this.message
    if (this.transition) {
      this.$nextTick(() => {
        TweenMax.from(this.$refs.text, 0.3, {
          opacity: 0,
          delay: this.delay
        })
      })
    }
    if (!this.update) {
      this.$nextTick(this.onTick)
    }
  },
  beforeDestroy() {
    TweenMax.killTweensOf(this.$refs.text)
    if (this.update) {
      TweenMax.ticker.removeEventListener("tick", this.onTick)
    }
  }
}
</script>

<style lang="scss">
.resizable-text-container {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  &.scrollbar {
    overflow: auto;
    align-items: flex-start;
  }
  .resizable-text {
    line-height: 1;
    user-select: none;
  }

  ol,
  ul {
    padding-left: 30px;
    li {
      text-align: left;
      margin-bottom: 2px;
    }
  }
}
</style>
